.input-label {
  font-size: 14px;
  color: #343a40;
}
.input-required {
  color: #ff7878;
}
.input {
  border: 1px solid #ced4da;
  font-size: 12px;
  width: 100%;
}
.input:focus {
  outline: none !important;
  border: 1px solid #008662;
  box-shadow: 0 0 0;
}
.switch {
  background: var(--op2mise-color-silver-mist);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) inset;
}
.switch.disabled {
  background: var(--op2mise-color-silver-gray);
}
.switch-container {
  cursor: pointer;
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  min-width: 80px;
  display: flex;
  justify-content: center;
}
.switch-active {
  background: var(--op2mise-color-primary) !important;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
}
.switch.disabled .switch-active {
  background: var(--op2mise-color-steel-blue-gray) !important;
  cursor: not-allowed;
}
/* .switch-inactive {
  cursor: not-allowed;
} */
.switch.disabled .switch-inactive {
  cursor: not-allowed;
  color: var(--op2mise-color-silver-mist);
}
/* .switch {
  background: #b1b1b1;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) inset;
}
.switch-container {
  cursor: pointer;
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  width: 33%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.switch-active {
  background: #008662;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
} */

.disabled {
  background: #ced4da;
  cursor: not-allowed;
}

.is-danger {
  font-size: 10px;
  color: #ff7878;
  margin: 0;
}
.border-danger {
  border: 1px;
}

.date-field {
  position: relative;
  /* width: 150px;
  height: 20px;
  color: white; */
  height: calc(10vh - 56px);
}

.date-field:before {
  position: absolute;
  top: 25%;
  left: 10px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}

.date-field::-webkit-datetime-edit,
.date-field::-webkit-inner-spin-button,
.date-field::-webkit-clear-button {
  display: none;
}

.date-field::-webkit-calendar-picker-indicator {
  position: absolute;
  padding: 0 0 5px 5px;
  top: 33%;
  right: 10px;
  color: black;
  opacity: 1;
}
