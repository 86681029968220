/* Required css for syncfusion multiselect dropdown */
/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css"; */

.table-body-row {
  border-bottom: 1px solid #e2e8f0;
  cursor: pointer;
}

.table-body-row-active {
  background-color: #116c53;
  border: 1px solid #116c53;
}

.table-body-row-active td {
  color: #ffffff;
}

.table-body-column {
  color: #656565;
  height: 56.08px;
  font-size: 13px;
  text-indent: 20px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Kendo elements */
/* @import './assets/kendo-theme-builder/op2mise-datagrid/dist/css/op2mise-datagrid.css';  */

/* .k-selected {
  background-color: #008662 !important;
  color: #ffffff !important;
} */

.k-pager-sizes {
  font-size: 12px;
  color: #656565;
}

.k-sorted>.k-cell-inner>.k-link>.k-sort-icon>.k-i-sort-desc-small::before {
  transform: rotate(180deg) !important;
  margin-top: 6px;
}

.k-data-grid-header-filtered>span>a>span {
  content: url('../src/shared/assets/icons/filtered.svg');
  height: 14px;
  width: 13px;
}

.k-data-grid-column {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #008662;
  border-color: #008662;
}

.ag-filter-apply-panel-button:last-child,
.ag-filter-apply-panel-button:last-child:hover {
  background-color: var(--op2mise-color-primary);
  font-size: 12px;
  font-weight: 100;
  border-radius: 4px;
  border: 1px solid var(--op2mise-color-primary);
  color: var(--op2mise-color-white);
  padding: 0px 24px;
  line-height: 36px;
  height: 36px;
}

.ag-filter-apply-panel-button:first-child {
  background-color: var(--op2mise-color-transparent);
  font-size: 12px;
  color: #6d6d73;
  border: none;
}

.ag-filter-apply-panel-button:first-child:focus {
  outline: none;
}