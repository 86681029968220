/* Campaign Table Styles */
.campaign-table-overlay-wrapper {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  gap: 5px;
}

.campaign-table-overlay-heading {
  font-size: 30px;
  font-weight: 300;
  line-height: 45px;
  color: var(--op2mise-color-primary);
}

.campaign-table-overlay-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.campaign-table-overlay-button-text {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  color: var(--op2mise-color-slate-gray);
}

.campaign-table-overlay-button:hover > .campaign-table-overlay-button-text {
  text-decoration: underline;
  text-underline-offset: 3px;
}
/* End of Campaign Table Styles */

.parent-container {
  height: calc(100% - 83px);
}

.spinner-div {
  height: 100%;
}

.default-text {
  color: #6D6D73;
}
.active-container {
  background: white;
}
.active-tab {
  cursor: pointer;
  color: #008662;
  font-weight: 600;
  box-shadow: 4px 5px 20px 5px #0000000d;
  background: white!important;
}

/* Start of DaySpendForm Styles */
.rows-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.day-week-rows, .excludes-rows {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.row-label {
  display: flex;
  gap: 0.5rem;
}

.row-label .day-start, .row-label .day-end, .row-label .day-part {
  max-width: 210px;
  margin: 0;
  width: 100%;
}

.row-label .day-allocation {
  max-width: 85px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.row-label .day-exclude {
  max-width: 53px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.day-week-rows .action-icon {
  cursor: pointer;
  height: 18px;
  -webkit-user-select: none;
  user-select: none;
}

.action-icon.disabled {
  cursor: not-allowed;
  background-color: transparent;
  filter: grayscale(1);
  opacity: 0.7;
}

.day-week-rows .exclude-switch-container {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  min-width: 53px;
}
/* End of DaySpendForm Styles */

/* Start of NotificationsForm Styles */
.notify-stations-icon {
  cursor: pointer;
}

.notify-stations-icon.disabled {
  cursor: auto;
  background-color: transparent;
  filter: grayscale(1);
  opacity: 0.7;
}
/* End of NotificationsForm Styles */

/*custom table*/
#tblIdx tr th:nth-child(1){
  width: 17%;
}

#tblIdx tr td:nth-child(1){
  width: 17.1%;
}

#tblIdx tr th:nth-child(8){
  width: 4%;
}

#tblIdx tr td:nth-child(12){
  width: 4%;
}

.customBorder {
  border-left: 0.50px #E2E8F0 solid;
  border-right: 0.50px #E2E8F0 solid;
  border-bottom: 0.50px #E2E8F0 solid;
}

.customBorderTarget {
  border-right: 0.50px #E2E8F0 solid;
  border-bottom: 0.50px #E2E8F0 solid;
  background: #FFFFFF;
}

.customBorderBooked {
  border-right: 0.50px #E2E8F0 solid;
  border-bottom: 0.50px #E2E8F0 solid;
  background: #F2F2F2;
  box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.10) inset;
}

.customBorderDarkBooked {
  border-right: 0.50px #E2E8F0 solid;
  border-bottom: 0.50px #E2E8F0 solid;
  background: #D9D9D9;
  box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.10) inset;
}

.borderRightTopEnd {
  border-right: 0.25px #D9D9D9 solid;
  border-top-right-radius: 6px;
}

.borderTop {
  border-top: 0.25px #F6F6F6 solid;
}


.inactive-tab {
  background: #f6f5f6;
  color: #6D6D73;
  cursor: not-allowed;
  font-weight: 300;
}

.customTD {
  padding: 10px;
  font-size: 12px;
  overflow-wrap: break-word;
  /*cursor: pointer;*/
  font-weight: 300;
  word-wrap: break-word
}

.custom-align-right {
  text-align: right;
}


.columns {
  color: #ffffff;
  background-color: #008662;
  height: 55.88px;
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  text-indent: 9px;
  position: relative;
  /* border: 1px solid #E2E8F0; */
}

.columns:first-child {
  border-top-left-radius: 6px;
  text-indent: 20px;
}

.columns:last-child {
  border-top-right-radius: 6px;
}

.w-xlg {
  width: 250px;
}

.w-lg {
  width: 180px;
}

.w-xsm {
  width: 60px;
}

.w-sm-blank {
  width: 75px;
}

.w-sm-blank-scroll {
  width: 91px;
}


.w-sm {
  width: 90px;
}


.w-xxsm {
  width: 40px;
}

.w-md {
  width: 110px;
}

.headerNoScroll {
  position: relative;
  text-align: center;
  color: #FAFAFA;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7.20px;
  background: #008662;
  border-bottom: 0.50px #007253 solid;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 4px;
}

.headerBorder{
  border-right: 0.50px #007253 solid;
}

.header {
  position: relative;
  text-align: center;
  color: #FAFAFA;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7.20px;
  background: #008662;
  border-right: 0.50px #007253 solid;
  border-bottom: 0.50px #007253 solid;
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 4px;
}

.headerDark {
  text-align: center;
  color: #FAFAFA;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 1px solid #007253;
  border-bottom: 0.50px #007253 solid;
  background: #005941;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 4px;
}

.scrollWidthFiller {
  width: 14px;
  background-color: #008662;
  border-top-right-radius: 6px;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 4px;
}

.labelHeader {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.filterIcons,
.clearAllFiltersIcon {
  cursor: pointer;
}

.clearAllFiltersIcon {
  width: 14px;
  height: 18px;
}

.icon {
  position: relative;
  width: 20px;
}

.icon img {
  position: absolute;
  left: 0;
  margin-top: 2px;
}

.icon:nth-child(3) img {
  margin-top: 3px;
}

.icon:nth-child(4) img {
  margin-top: -2px;
}


/* custom table end */
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.horizontal {
  flex-direction: row;
  width: 100%;
}

.horizontal .flex-item {
  flex: 1;
}

.flex-item {
  padding: 10px;
  width: 100px;
  height: 75px;
  margin: 5px;
  line-height: 75px;
  color: white;
  font-size: 3em;
  text-align: center;
}

.tab-container{
  font-size: 14px;
  width: 249.04px;
  height: 39px;
  background: #f6f5f6;
}

.active-content {
  z-index: 10;
  border-radius: 0 5px 5px 5px;
  box-shadow: 7px 10px 10px 3px #0000000d;
}

.form-contianer {
  padding: 4rem 11rem 0 11rem;
}

.campagin-form {
  padding: 0 4rem 0 0;
  margin-top: 4rem;
  margin-left: 11rem;
  margin-right: 11rem;
  overflow-x: hidden;
}

.form-btn-container{
  padding: 0rem 4rem 2rem 0rem;
}

.remove-top {
  width: 8rem;
  font-size: 14px;
  line-height: 21px;
}
.r-container {
  width: max-content;
}
.input-container{
  width: 50px!important;
}
.input-container-market{
  width: 10%;
}
.market-form-container{
  overflow-y: auto;
  max-height: calc(100vh - 400px);
}


.border-solid-gray {
  border: 1px solid #E2E8F0;
}

.closeBtn{
  cursor: pointer;
  font-size: 12px;
  color: #6D6D73;
}

.switchInput{
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10px;
  height: 45px;
}

.switchInput.switchInputDisabled{
  cursor: not-allowed;
}

.switchBtn {
  width: 39.95px!important;
  height: 22px;
}
.switchBtn:focus {
  outline: none !important;
  border:1px solid var(--op2mise-color-primary);
  box-shadow: 0 0 0 ;
}
.switchBtn-off{
  background-image: url('../../../public/switch.svg')!important;
  border-color: var(--op2mise-color-emerald-green);
  background-position: bottom 2px right 20px!important;
  background-size: 16px;
}
.switchBtn-disabled{
  background-image: url('../../assets/icons/switch-disabled.svg')!important;
  border-color: var(--op2mise-color-steel-blue-gray);
  background-position: bottom 2px right 20px!important;
  background-size: 16px;
}
.switchLabel{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.program-input{
  border-radius: 4px;
  height: 34px;
  border: 1px solid #CED4DA;
  text-align: right;
  padding: 10px;
  width: 50px;
}
.center-container {
  display: flex;
  align-items: center;
}

.err-text {
  font-size: 10px;
  color: red;
  display: flex;
  align-items: center;
}

.rounded-5{
  border-radius: 5px!important;
}
.pt-0{
  padding-top: 0px!important;
}
.text-14 {
  font-size: 14px!important;
}
.text-18{
  font-size: 18px!important;
}

.text-black{
  color: black;
}

.text-align {
  text-align: right;
}

.pt-8px{
  padding-top: 8px;
}
.w-auto{
  width: auto;
}
.w-15px {
  width: 15px;
}
.w-15{
  width: 15%;
}
.w-20{
  width: 20%;
}
.w-30{
  width: 30%;
}
.w-40{
  width: 40%;
}
.w-50px{
  width: 50px;
}
.w-55{
  width: 55%;
}
.w-60{
  width: 60%;
}
.w-85px{
  width: 85px;
}
.w-95px{
  width: 95px;
}
.w-274px{
  width:274px;
}
.z-10{
  z-index: 10;
}

.min-h-36{
  min-height: 36rem;
}

.min-h-30{
  min-height: 22rem;
}

.m-h-731px{
  min-height: 731px;
}

.max-h-32{
  max-height: 32rem;
}

.min-max-h-20{
  overflow-y: auto;
  height: calc(100vh - 300px);
}

.max-h-40{
  max-height: 40rem;
}

.overflow-auto{
  overflow: auto;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.cursor-pointer{
  cursor: pointer;
}

.gap-3{
  gap:10px!important;
}

.gap-5{
  gap:5px!important;
}

.gap-15{
  gap:15px
}
.gap-10{
  gap:10px!important;
}
.bg-success {
  background: #008662;
  color: white;
}

.c-box-shadow{
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.form{
  height: calc(100vh - 170px);
  justify-content: space-between;
}

.customScroll::-webkit-scrollbar-track {
    border-right: 1px solid #dee3eb;
    border-left: 1px solid #dee3eb;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
}
.customScroll::-webkit-scrollbar {
    background-color: #fafafa;
    width: 14px;
    padding: 5px;
}

.campaign-station-notification-modal .modal-body {
  padding: 3rem;
}

.campaign-station-notification-modal-content  {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Track */
#tblIdx::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px red !important;
  border-radius: 10px !important;
}

/* Date */
input[type=date]:invalid::-webkit-datetime-edit {
  color: #6c5c5c;
}


.clear-market-spend-button {
  color: var(--op2mise-color-primary);
  font-size: 14px;
  cursor: pointer;
}

.clear-market-spend-button-disabled {
  color: var(--op2mise-color-silver-mist);
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width:1024px ) {
   .input-container{
      width:15%;
   }
   .remove-top{
    width: 50%;
   }
   .m-w-100{
    width: 100%!important;
   }
   .m-p-2{
    padding: 2rem;
   }
   .m-w-30{
    width: 30%!important;
   }
}

/* Notifications Modal  */
.market-stations-modal {
  width: 100%;
  max-width: 75vw;
}

.station-wrapper {
  padding: 50px;
  max-height: 700px;
}

.compose-email-form-modal {
  width: 100%;
  max-width: 700px;
}

.compose-email-form-wrapper {
  padding: 50px;
  max-height: 80vw;
}

.compose-email-form-title {
  color: #343a40;
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.disabled-compose-email-form-action-button {
  cursor: not-allowed;
  opacity: 0.5;
}

.text-area {
  width: 100%;
  height: 100px;
  padding: 8px 13px;
  color: #656565;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #d3d2d2;
  resize: none;
}

.text-area::placeholder {
  color: #b1b1b1;
}

.text-area:focus {
  outline: none;
  border-color: #008662;
}

.text-area:disabled {
  cursor: not-allowed;
  background-color: #ced4da;
  color: #787e82;
}

.text-area-label {
  color: #343a40;
  font-size: 14px;
  line-height: 16.71px;
  margin-bottom: 5px;
}

.help-tooltip-modal {
  margin-top: -50px;
  width: 100%;
  max-width: 590px;
}

.help-tooltip-wrapper {
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.1);
}

.help-tooltip-wrapper p {
  font-size: 12px;
}

.template-name-form-modal,
.send-test-email-form-modal {
  width: 100%;
  max-width: 500px;
}

.template-name-form-wrapper,
.send-test-email-form-wrapper {
  padding: 50px;
  max-height: 80vw;
}

.template-name-form-title,
.send-test-email-form-title {
  color: #343a40;
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.compose-email-form-error-message {
  font-weight: 400;
  font-size: 12px;
  color: #ff7878;
}

.successfully-notified-modal {
  width: 100%;
  max-width: 500px;
  padding: 10px;
}

.successfully-notified-wrapper {
  height: 150px;
  text-align: center;
  padding-top: 40px;
}

.successfully-notified-title {
  font-size: 16px;
}