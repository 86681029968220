.op2mise-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.op2mise-modal.e-dialog {
  padding: 3.5rem;
  gap: 35px;
  border-radius: 5px;
  box-shadow: none;
}

.op2mise-modal .e-dlg-content {
  padding: 0;
}

.op2mise-modal .e-footer-content {
  display: flex;
  padding: 0;
  gap: 15px;
}

.op2mise-modal.footer-align-start .e-footer-content {
  justify-content: start;
}

.op2mise-modal.footer-align-center .e-footer-content {
  justify-content: center;
}

.op2mise-modal.footer-align-end .e-footer-content {
  justify-content: end;
}

.op2mise-modal .e-footer-content .e-btn {
  font-size: 12px;
  color: var(--op2mise-color-dim-gray) !important;
  font-weight: 400 !important;
  font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
  margin: 0;
}

.op2mise-modal .e-footer-content .e-btn:hover {
  background-color: transparent !important;
}

.op2mise-modal .e-footer-content .e-btn.e-primary {
  color: var(--op2mise-color-white) !important;
  background-color: var(--op2mise-color-primary) !important;
  padding: 11px 24px;
}

.op2mise-modal .e-footer-content .e-btn.e-primary:hover {
  background-color: var(--op2mise-color-primary-shade) !important;
}

.op2mise-modal .e-footer-content .e-btn.e-primary:disabled {
  background-color: var(--op2mise-color-silver-mist) !important;
}

.op2mise-modal .modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.op2mise-modal p {
  font-size: 18px;
  text-align: center;
  line-height: 22.5px;
  font-weight: 275;
  margin: 0;
  overflow-y: hidden;
}

.op2mise-modal p.content-emphasized {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  margin-top: 15px;
}

.op2mise-modal p.content-strong {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
}
