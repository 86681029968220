.promptModalBody {
    padding: 35px;
    text-align: center;
}

.button {
    width: 100%;
    margin-top: 20px;
}

.button:first-child {
    margin-top: 50px;
}