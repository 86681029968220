.sidebarHeader {
  position: relative;
  width: 100%;
  height: 165px;
}

.vaiLogo {
  width: auto;
  height: auto;
  max-width: 180px;
  max-height: 100px;
}

.vaiLogoSmall {
  width: auto;
  height: auto;
  max-height: 50px;
  max-width: 70px;
}

.vaiLogo,
.vaiLogoSmall {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activeLink {
  color: #fafafa !important;
  font-weight: 600;
}

.whiteIcon,
.activeLink img {
  filter: brightness(0) invert(1);
}

.greyIcon {
  filter: brightness(0) saturate(100%) invert(51%) sepia(8%) saturate(243%) hue-rotate(161deg) brightness(94%) contrast(96%);
}

.sideBarListDiv{
  width: 100%;
  height: calc(100vh - 337px);
  overflow: auto;
}

.sidebarStateListDiv{
  width: 100%;
  height: calc(100vh - 420px);
  overflow: auto;
}

/* width */
.sidebarStateListDiv::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
.sidebarStateListDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important; 
  border-radius: 10px !important;
}
 
/* Handle */
.sidebarStateListDiv::-webkit-scrollbar-thumb {
  background: #787e82  !important; 
  border-radius: 10px !important;
}

/* Handle on hover */
.sidebarStateListDiv::-webkit-scrollbar-thumb:hover {
  background: #fff !important; 
}

/* width */
.sideBarListDiv::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
.sideBarListDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important; 
  border-radius: 10px !important;
}
 
/* Handle */
.sideBarListDiv::-webkit-scrollbar-thumb {
  background: #787e82  !important; 
  border-radius: 10px !important;
}

/* Handle on hover */
.sideBarListDiv::-webkit-scrollbar-thumb:hover {
  background: #fff !important; 
}

.collapseWrapper {
  padding-right: 5px;
  width: 100%;
  padding-top: 12px;
  padding-left: 5px;
}

.collapseLabel {
  cursor: pointer;
  color: #787e82;
  display: flex;
  margin-left: 20px;
  position: relative;
  padding-bottom: 16px;
  font-weight: bold;
}

.collapseLabelTitle {
  margin-left: -5px;
}

.collapseLabel img {
  margin-right: 20px;
}

.collapsibleSubLinks {
  margin-left: 5px;
}

.collapsibleSubLinks .title {
  margin-left: 12.5px;
}

.chevRightGreyIcon {
  position: absolute;
  right: 0;
  margin-top: 6px;
}

.rotateIcon {
  transform: rotate(90deg);
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.iconWrapper {
  width: 20px;
}

.colapseIconWrapper {
  width: 41px;
}