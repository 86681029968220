.activeLink {
  color: #fafafa !important;
  font-weight: 600;
}

.sideNav-container {
  display: flex;
  flex-direction: column;
}

.sideNav {
  padding: 16px 25px;
  font-size: 15px;
  color: #787e82;
  cursor: pointer;
  display: flex;
  font-weight: bold;
}

.sideNav:first-child {
  padding-top: 6px;
  margin-top: 0.5px;
}