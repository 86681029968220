.ag-theme-op2mise-overview {
  --ag-background-color: var(--op2mise-color-white);
  --ag-cell-horizontal-padding: 16px;
  --ag-borders: solid 1px;
  --ag-border-color: #c4c4c4;
  --ag-data-color: var(--op2mise-color-dark-cyan);
  --ag-font-size: 12px;
  --ag-grid-size: 12px;
  --ag-header-background-color: var(--op2mise-color-ash-gray);
  --ag-header-column-resize-handle-display: none;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-color: #c4c4c4;
  --ag-header-foreground-color: var(--op2mise-color-steel-blue-gray);
  --ag-header-height: 30px;
  --ag-icon-font-family: agGridMaterial;
  --ag-list-item-height: 45.4px;
  --ag-menu-background-color: var(--op2mise-color-white);
  --ag-row-height: 45.4px;
  --ag-subheader-toolbar-background-color: var(--op2mise-color-white);
  --ag-tooltip-background-color: var(--op2mise-color-white);
}

.ag-theme-op2mise-overview .ag-body.ag-layout-auto-height{
  height: 100% !important;
}

.ag-theme-op2mise-overview .ag-body-horizontal-scroll {
  display: none !important;
}

/* .ag-theme-op2mise-overview .ag-body-vertical-scroll {
  display: none !important;
}

.ag-theme-op2mise-overview .ag-body-vertical-scroll-viewport {
  overflow-y: hidden;
} */

.ag-theme-op2mise-overview .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 0px;
}

.ag-theme-op2mise-overview .ag-cell {
  border-right: solid 1px var(--op2mise-color-light-blue-gray);
  min-width: 60px;
  text-align: left;
} 

.ag-theme-op2mise-overview .ag-header {
  font-weight: 300;
  text-align: left;
}

.ag-theme-op2mise-overview .ag-header-cell {
  box-shadow: inset 0 -3px 4px rgba(0,0,0,.1);
}

.ag-theme-op2mise-overview .ag-header-cell-label {
  /** this class makes the grid header text appear on center position */
  display: flex;
  justify-content: center;
}

.ag-theme-op2mise-overview .ag-header-text-align-left .ag-header-cell-label {
  /** this class makes the grid header text appear on left position */
  display: flex;
  justify-content: start;
}

.ag-theme-op2mise-overview .ag-header-text-align-right .ag-header-cell-label {
  /** this class makes the grid header text appear on right position */
  display: flex;
  justify-content: end;
}

.ag-theme-op2mise-overview .ag-header-group-cell {
  justify-content: center;
}

.ag-theme-op2mise-overview .ag-root-wrapper {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border: none;
}

.ag-theme-op2mise-overview .ag-pinned-left-header {
  border-right: var(--ag-borders-critical) var(--op2mise-color-dark-cyan);
}

.ag-theme-op2mise-overview .ag-pinned-right-header {
  border-left: var(--ag-borders-critical) var(--op2mise-color-dark-cyan);
}

.ag-theme-op2mise-overview .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right: var(--ag-borders-critical) var(--op2mise-color-dark-cyan);
}

.ag-theme-op2mise-overview .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  border-left: var(--ag-borders-critical) var(--op2mise-color-dark-cyan);
}

.ag-theme-op2mise-overview .ag-row {
  border-bottom: solid 1px var(--op2mise-color-light-blue-gray);
}