.checkboxDiv {
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box {
  border-radius: 4px;
}

.circle {
  border-radius: 50%;
}

.box,
.circle {
  border: 1px solid #008662;
  width: 17px;
  height: 17px;
  position: relative;
  cursor: pointer;
  padding-top: 4px;
  padding-left: 3px;
}

.notChecked {
  background-color: #ffffff;
}

.checked {
  background-color: #008662;
}

.checkIcon {
  position: absolute;
  content: url('../../assets/icons/check.svg');
}

.label {
  color: #343a40;
  font-size: 14px;
  line-height: 18px;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  pointer-events: none;
}

.disabledNotChecked {
  border: 1px solid #b1b1b1;
}

.disabledChecked {
  background-color: #b1b1b1;
  border: 1px solid #b1b1b1;
}
