.switchDiv {
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch,
.activeSwitch,
.disabledSwitch,
.disabledActiveSwitch {
  position: relative;
  width: 40px;
  border-radius: 25px;
  height: 22px;
}

.switchWithText,
.activeSwitchWithText {
  padding-left: 5px;
  padding-right: 5px;
  width: 55px;

  font-weight: 100;
}

.switchWithText {
  color: #008662;
}

.activeSwitchWithText {
  color: #ffffff;
}

.text,
.activeText {
  font-size: 12px;
  position: absolute;
  margin-top: 1px;
}

.text {
  right: 0;
  margin-right: 10px;
}

.activeText {
  margin-left: 5px;
}

.switch {
  border: 1px solid #008662;
}

.switch::after {
  content: url('../../assets/icons/circle-brown.svg');
  margin-left: 3px;
}

.switch::after:focus {
  content: url('../../assets/icons/circle-darkbrown.svg');
}

.activeSwitch {
  border: 1px solid #008662;
  background-color: #008662;
  position: relative;
}

.activeSwitch::before {
  position: absolute;
  content: url('../../assets/icons/circle-white.svg');
  right: 0;
  margin-right: 3px;
}

.switch,
.activeSwitch {
  cursor: pointer;
}

.disabledSwitch,
.disabledActiveSwitch {
  cursor: not-allowed;
}

.disabledSwitch {
  border: 1px solid #b1b1b1;
}

.disabledSwitch::after {
  content: url('../../assets/icons/circle-lightbrown.svg');
  margin-left: 3px;
}

.disabledActiveSwitch {
  border: 1px solid #b1b1b1;
  background-color: #b1b1b1;
  position: relative;
}

.disabledActiveSwitch::before {
  position: absolute;
  content: url('../../assets/icons/circle-white.svg');
  right: 0;
  margin-right: 3px;
}

.label {
  color: #343a40;
  font-size: 14px;
  line-height: 22px;
  margin-left: 5px;
}

.disabledLabel {
  color: #B1B1B1;
}