.defaultButton {
  background-color: transparent;
  font-size: 12px;
  color: #6d6d73;
  border: none;
}

.defaultButton:focus {
  outline: none;
}

.button {
  background-color: #008662;
  font-size: 12px;
  font-weight: 100;
  border-radius: 4px;
  border: 1px solid #008662;
  color: #ffffff;
}

.button:active,
.button:hover {
  background-color: #116c53;
  border: 1px solid #116c53;
}

.button:focus {
  border: 1px solid #116c53;
  outline: none;
}

.button:disabled {
  background-color: #b1b1b1;
  border: 1px solid #b1b1b1;
  cursor: not-allowed;
}

.buttonSm {
  padding: 0px 24px;
  line-height: 24px;
  height: 24px;
}

.buttonMd {
  padding: 0px 24px;
  line-height: 36px;
  height: 36px;
}

.buttonLg {
  padding: 0px 24px;
  line-height: 40px;
  height: 40px;
}

.buttonWithCaret {
  display: flex;
  padding: 0px;
}

.buttonText {
  text-align: start;
  padding-left: 12px;
  overflow-x: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caretIcon {
  content: url('../../assets/icons/triangle-down.svg');
  margin-top: 15px;
  padding-right: 12px;
  padding-left: 4px;
}
