.e-custom-button {
  background: var(--op2mise-color-primary) !important;
  font-weight: normal;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  line-height: 18px;
  height: 36px;
  width: fit-content;
  padding: 11px 24px;
  box-shadow: none;
  border-radius: 4px;
  color: var(--op2mise-color-white);
}

.e-custom-button:active,
.e-custom-button:hover {
  color: var(--op2mise-color-white);
  background: var(--op2mise-color-primary-shade) !important;
  box-shadow: none;
}

.e-custom-button:focus {
  border: 1px solid var(--op2mise-color-primary-shade);
  outline: none;
}

.e-custom-button:disabled {
  background: var(--op2mise-color-silver-mist) !important;
  color: var(--op2mise-color-white);
}
