/** CUSTOM STYLES FOR CUSTOM COMPONENTS **/
.ag-footer-no-pagination {
  height: 50px;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  background-color: var(--op2mise-color-white);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 6px 6px;
  box-shadow: 0 10px 20px rgba(0,0,0,.1);
  position: relative;
}

.ag-checkbox-no-label .ag-cell-wrapper {
  width: min-content;
  margin: auto;
}

.ag-checkbox-no-label .ag-selection-checkbox {
  margin: 0;
  width: 22px;
}

.ag-checkbox-no-label .ag-checkbox-input-wrapper::after {
  color: var(--op2mise-color-light-blue-gray);
  font-size: 22px;
}

.ag-checkbox-no-label .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--op2mise-color-primary);
}

.ag-op2mise-button {
  display: flex;
  align-items: center;
  background-color: var(--op2mise-color-primary);
  font-weight: 100;
  padding: 5px 15px;
  height: 32px;
  width: fit-content;
  border: none;
  border-radius: 4px;
  color: var(--op2mise-color-white);
  text-align: center;
  cursor: pointer;
}

.ag-op2mise-button:disabled {
  background-color: #b1b1b1;
}

.ag-overlay-no-rows-center{
  font-size: large;
}

.ag-pinned-left-cols-container{
  border-right: var(--ag-borders-critical) var(--op2mise-color-dark-cyan);
}
.ag-pinned-right-cols-container{
  border-left: var(--ag-borders-critical) var(--op2mise-color-dark-cyan);
}
.ag-theme-op2mise .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell){
  border-left: none !important;
}

.ag-horizontal-left-spacer, .ag-horizontal-right-spacer{
  background-color: #ededed;
  overflow: auto;
}

.action-buttons {
  min-width: 102px;
  max-width: 102px;
  margin-left: 6px;
  margin-right: 6px;
  /* justify-content: center;
  display: flex;
  flex-direction: column; */
  flex: 1; 
  height: 100%; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;

}

.action-buttons button {
  display: flex;
  height: 38px;
  padding: 1px 5px 0px 5px;
  background-color: #008662;
  font-size: 12px;
  font-weight: 100;
  border-radius: 4px;
  border: 1px solid #008662;
  color: #ffffff;
  width: 100%;
}

.action-buttons button + button {
  margin-top: 5px;
}

.action-buttons button:active,
.action-buttons button:hover {
  background-color: #116c53;
  border: 1px solid #116c53;
}

.action-buttons button:focus {
  border: 1px solid #116c53;
  outline: none;
}

.action-buttons button:disabled {
  background-color: #b1b1b1;
  border: 1px solid #b1b1b1;
  cursor: not-allowed;
}