
.op2mise-auto-group-header .ag-header-cell-label {
  margin-left: 16px;
}

/* Custom CSS */
.ag-grid-header-expand-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 20%;
  margin-right: 16px;
  height: 100%;
  align-items: inherit;
}

.ag-grid-header-expand-icon {
  height: 15px;
  width: 15px;
}

.ag-header-expand-cell-border::before {
  content: "";
  position: absolute;
  z-index: 1;
  display: var(--ag-header-column-separator-display);
  width: var(--ag-header-column-separator-width);
  height: var(--ag-header-column-separator-height);
  top: calc(50% - var(--ag-header-column-separator-height)* 0.5);
  background-color: var(--ag-header-column-separator-color);
}