.vaiProfilePhoto {
  filter: drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.25));
  border-radius: 100px;
  height: 55px;
  width: 55px;
}

.vaiShrinkPhoto {
  height: 32px;
  width: 32px;
}

.vaiHide {
  display: none;
}

.vaiNotification {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 25px;
  height: 25px;
  background: #3782bd;
  border-radius: 100px;
  z-index: 1;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-top: 3.5px;
  color: #fafafa;
  font-weight: 600;
}

.vaiShrinkNotification {
  height: 14px;
  width: 14px;
  font-size: 9px;
  padding-top: 0px;
  top: -5px;
  right: -5px;
}

.vaiStatus {
  position: absolute;
  height: 17.19px;
  width: 17.19px;
  background-color: #3cc13b;
  z-index: 1;
  bottom: -3px;
  right: -2px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
}

.vaiShrinkStatus {
  bottom: 0;
  height: 10px !important;
  width: 10px !important;
}

.vaiUsername {
  margin-top: 8px;
  font-size: 15px;
  line-height: 17.9px;
  color: #b1b1b1;
  font-weight: 500;
}

.vaiUserTitle {
  font-size: 12px;
  line-height: 14px;
  color: #6d6d73;
}

.vaiChevUp {
  position: absolute;
  right: 0;
  margin-right: 20px;
  top: 15px;
  transform: rotate(270deg);
}

.vaiChevDown {
  position: absolute;
  right: 0;
  margin-right: 20px;
  bottom: 28px;
  transform: rotate(90deg);
}

.vaiChevron:before {
  content: url('../../assets/icons/Chevron-Down.png');
  position: absolute;
  right: 0;
  bottom: 0;
}

.vaiSignOutText {
  position: absolute;
  font-size: 15px;
  line-height: 18px;
  color: #787e82;
  margin-left: 17px;
  margin-top: 4.5px;
  font-weight: bold;
}

.profileLink {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 20px;
  margin-left: 17px;
}

.profileLink:hover {
  color: #787e82;
}

.profileLink img {
  filter: invert(0.5);
}

.activeLink {
  color: #ffffff !important;
}

.activeLink img {
  filter: brightness(0) invert(1);
}

.activeProfileLink {
  color: #ffffff;
}

.profileLinkHideText {
  display: none;
}

.profileLinkCenterText {
  margin-left: 10px;
}

.chevLeftCircleIcon {
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: -15px;
}

.link {
  color: #787e82 !important;
}

.linkText {
  position: absolute;
  line-height: 18px;
  margin-left: 20px;
  margin-top: 4.5px;
}

.vaiUsername, .vaiUserTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profileText {
  margin-left: 16.5px;
  margin-top: 1px;
}

.chevLeftRotateIcon {
  transform: rotate(180deg);
}