.searchInput {
    padding: 3px 10px;
    width: 100%;
    height: 37px;
    background: #ffffff;
    color: #6d6d73;
    border: 1px solid #ced4da;
    border-right: none;
    box-sizing: border-box;
    border-radius: 4px 0px 0px 4px;
    font-size: 12px;
    line-height: 14px;
    outline: none;
    box-shadow: none;
}

.searchInput::placeholder {
    color: #b1b1b1;
}

.searchInput:disabled {
    background: #e9ecef;
}
  
.searchIconWrapper {
    border: 1px solid #ced4da;
    border-left: none;
    height: 37px;
    border-radius: 0px 4px 4px 0px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 3px;
    background: #ffffff;
    display: flex;
}

.searchIcon {
    height: 18px;
    margin-top: 4px;
    cursor: pointer;
}

.closeIcon {
    cursor: pointer;
    height: 15px;
    padding-right: 5px;
    margin-top: 6px;
}

.searchIcon:hover,
.closeIcon:hover {
    filter: brightness(0) saturate(100%) invert(25%) sepia(73%) saturate(2421%) hue-rotate(150deg) brightness(98%) contrast(103%);
    outline: none;
}

.smallInput {
    height: 30px;
}

.smallInput .searchIcon {
    margin-top: 0px;
}

.smallInput .closeIcon {
    margin-top: 2px;
}