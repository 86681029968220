.fieldLabel,
.fieldLabelDisabled {
    color: #343a40;
    font-size: 14px;
    margin-bottom: 5px;
}

.fieldLabelDisabled {
    color: #b1b1b1;
}

.fieldLabel:first-child,
.fieldLabelDisabled:first-child {
    margin-bottom: 0px;
}

.fieldLabel span {
    color: #ff0000;
}

.errorMessage {
    font-size: 12px;
    color: #ff7878;
}